export const environment = {
  production: false,
  bookingWebcomponentUrl: 'https://webcomponent.calenso.io/booking.html',
  clientDomain: 'https://dashboard.calenso.io',
  smartWidgetUrl: 'https://smartwidget.calenso.io',
  bookingPageUrl: 'https://book.calenso.io',
  hostName: 'https://api.calenso.io',
  authServiceHost: 'https://auth.calenso.io',
  authServiceRestApiV1: '/api/',
  exportNodeServiceHost: 'https://export-queue.calenso.io',
  exportNodeServiceRestApiV1: '/api/',
  notificationNodeServiceHost: 'https://notification-queue.calenso.io',
  notificationNodeServiceRestApiV1: '/api/',
  workflowNodeServiceHost: "https://workflow.calenso.io",
  workflowNodeServiceRestApiV1: "/api/",
  restAPI: '/api/v1/',
  v2RestAPI: '/api/v2/',
  encryptedKey: 'Vuc28iLCJhdWQiOjYsImlhdCI6MTU2NDU4MTPR',
  configJsonPath: 'fe_assets/config.uat.json',
  filesImagePath: 'https://api.calenso.io/files/',
  imagePath: 'https://api.calenso.io/files/workers/avatar/',
  bookingPageBackgroundImagePath: 'https://api.calenso.io/files/partners/booking_page_background_logo/',
  partnersImagePath: 'https://api.calenso.io/files/partners/logo/',
  landingPageImagePath: 'https://api.calenso.io/files/partners/landingpage_logo/',
  faviconImagePath: 'https://api.calenso.io/files/partners/favicon_logo/',
  emailImagePath: 'https://api.calenso.io/files/partners/email_logo/',
  eventImagePath: 'https://api.calenso.io/webroot/files/events/image/',
  storeImagePath: 'https://api.calenso.io/webroot/files/stores/logo/',
  svgDealsImagePath: 'https://api.calenso.io/files/deals/svg_logo/',
  cssTemplatesImagePath: 'https://api.calenso.io/images/templates/',
  deployUrl: 'https://dashboard.calenso.io',
  bookingSiteUrl: 'https://book.calenso.io',
  bookingSiteEventUrl: 'https://book.calenso.io/{partner-name}/events',
  registerUrl: 'https://signup.calenso.io/',
  webcomponentWidgetUrl: 'https://webcomponent.calenso.io',
  pricingTableWebComponentUrl: 'https://pricing.calenso.io',
  iframeWidgetUrl: 'https://widget.calenso.io',
  registerationUrl: 'https://cloud.calenso.com/testversion/',
  version: '7.6.4',
  googleTagManagerCode: 'GTM-MJC5XGQ',
  redirect_uri: 'https://dashboard.calenso.io/app/settings/calendars',
  calendly_redirect_uri: 'https://dashboard.calenso.io/app/settings/calendly',
  setupPageRedirectURI: 'https://dashboard.calenso.io/app/setup/appointment/account?step=4',
  reseller_contract_url: 'https://www.calenso.com/reseller-contract',
  smartWidget: {
    integrationURL: 'https://smartwidget.calenso.io/assets/smart-widget/main.js'
  },
  azureSSO: {
    redirectUri: 'https://dashboard.calenso.io/auth/sso'
  },
  stripePublicKey: "pk_test_qC6Q2zbwLgHgHEzXkHdKATbR",

  // secrets variables
  stripeConnectUrl: "",
  stripeCoupon: {
    flatrate: "",
    whitelabel: ""
  },
  office365Conf: {
    url: "",
    clientId: "",
    redirectURI: "",
    setupPageRedirectURI: "",
    response_type: "",
    state: "",
    scope: "",
    plainRedirectUrl: "",
    plainSetupPageRedirectURI: "",
    teamsScope: "",
    teamsRedirectURI: "",
    plainTeamsRedirectURI: ""
  },
  googleConf: {
    url: "",
    response_type: "",
    accessType: "",
    clientId: "",
    redirectURI: "",
    state: "",
    scope: "",
    approvalPrompt: "",
    setupPageRedirectURI: ""
  },
  bexioConf: {
    url: "",
    grantType: "",
    clientId: "",
    redirectURI: "",
    bexio_login_redirect: "",
    scope: [],
    bexio_uuid: ""
  },
  zoomConfig: {
    url: "",
    response_type: "",
    client_id: "",
    redirect_uri: ""
  },
  gotoMeetingConfig: {
    url: "",
    response_type: "",
    client_id: "",
    redirect_uri: ""
  },
  webexConfig: {
    url: "",
    response_type: "",
    client_id: "",
    redirect_uri: "",
    scope: ""
  }
};
